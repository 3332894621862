export const ReportsPerMonth = {
  type: 'line',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Jumlah',
        data: [],
        backgroundColor: 'rgba(255, 99, 132,.5)',
        borderColor: '#db571f',
        borderWidth: 3,
      },
    ],
  },
  options: {
    responsive: true,
    //maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      /*title: {
        display: true,
        text: "Reports per Month",
      },*/
      legend: {
        position: 'bottom',
      },
      datalabels: {
        color: '#ffffff',
      },
    },
  },
}

export default ReportsPerMonth
