<template>
  <div>
    <b-row align-content="center">
      <b-col class="text-center"><strong>Report Per Month</strong></b-col>
      <b-col class="text-center"
        ><strong>Report Per Status </strong>
        <div>
          <strong>Total {{ totalStatus }}</strong>
        </div></b-col
      >
      <b-col class="text-center"
        ><strong>Report Per Equipment</strong>
        <div>
          <strong>Total {{ totalEquipment }}</strong>
        </div></b-col
      >
    </b-row>
    <b-row align-v="center">
      <div class="col-md-4">
        <canvas id="report"></canvas>
      </div>
      <div class="col-md-4"><canvas id="report-status"></canvas></div>
      <div class="col-md-4"><canvas id="report-equipment"></canvas></div>
    </b-row>
    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col-md-12">
        <strong>Report &amp; Record</strong>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Equipment</th>
              <th>Part</th>
              <th>Hasil</th>
              <th>Kekritisan</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, index) in lastreports" :key="r.id_report" @click="viewReport(index)">
              <td>{{ r.date }}</td>
              <td>{{ r.nomor_equipment }}</td>
              <td>{{ r.nama_part }}</td>
              <td v-html="r.inspection_result"></td>
              <td>{{ r.critically }}</td>
              <td>{{ r.status }}</td>
            </tr>
            <tr>
              <td style="text-align: center" colspan="6" v-if="lastreports.length == 0">
                Tidak ada data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <strong>Notifikasi</strong>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>No</th>
              <th>Date</th>
              <th>Pesan</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(r, index) in notifications"
              :key="r.idnotifikasi"
              @click="reportDetail(r.id_report)"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ r.tanggal }}</td>
              <td>{{ r.pesan }}</td>
              <td>
                <a :href="r.url" target="_blank" class="btn btn-icon btn-light btn-sm">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <i class="flaticon-eye text-primary"></i>
                    <!--end::Svg Icon-->
                  </span>
                </a>
              </td>
            </tr>
            <td style="text-align: center" colspan="3" v-if="notifications.length == 0">
              Tidak ada data
            </td>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal
      id="info-modal"
      size="xl"
      :title="`${modalInfo.nomor_equipment} - ${modalInfo.nama_part}`"
    >
      <b-row>
        <b-col>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Date"
            label-for="date"
          >
            <b-form-input
              id="date"
              size="sm"
              v-model="modalInfo.date"
              disabled
            ></b-form-input> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="No Report"
            label-for="no_report"
          >
            <b-form-input
              id="no_report"
              size="sm"
              v-model="modalInfo.no_report"
              disabled
            ></b-form-input> </b-form-group
          ><b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
            <b-col sm="5" class="text-sm-left">Issued to</b-col>
            <b-col>
              <ul>
                <li v-for="(item, index) in JSON.parse(modalInfo.issued_to)" :key="index">
                  {{ JSON.parse(item).label }}
                </li>
              </ul>
            </b-col> </b-row
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Pabrik"
            label-for="nama_pabrik"
          >
            <b-form-input
              id="nama_pabrik"
              size="sm"
              v-model="modalInfo.nama_pabrik"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Plant"
            label-for="nama_plant"
          >
            <b-form-input
              id="nama_plant"
              size="sm"
              v-model="modalInfo.nama_plant"
              disabled
            ></b-form-input> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Equipment"
            label-for="nama_equipment"
          >
            <b-form-input
              id="nama_equipment"
              size="sm"
              v-model="modalInfo.nama_equipment"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Nama Part"
            label-for="nama_part"
          >
            <b-form-input
              id="nama_part"
              size="sm"
              v-model="modalInfo.nama_part"
              disabled
            ></b-form-input> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Method"
            label-for="method"
          >
            <b-form-input id="method" size="sm" v-model="modalInfo.method" disabled></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Inspector"
            label-for="inspector"
          >
            <b-form-input
              id="inspector"
              size="sm"
              v-model="modalInfo.inspector_name"
              disabled
            ></b-form-input> </b-form-group
          ><b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
            <b-col sm="5" class="text-sm-left">Co-Inspector</b-col>
            <b-col>
              <ul>
                <li v-for="(item, index) in JSON.parse(modalInfo.co_inspector)" :key="index">
                  {{ JSON.parse(item).label }}
                </li>
              </ul>
            </b-col>
          </b-row></b-col
        ><b-col
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Hasil Pemeriksaan"
            label-for="inspection_result"
          >
            <vue-editor
              id="inspection_result"
              :editorToolbar="customToolbar"
              v-model="modalInfo.inspection_result"
              :disabled="true"
            ></vue-editor> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Criticallity"
            label-for="criticallity "
          >
            <b-form-input
              id="criticallity"
              size="sm"
              v-model="modalInfo.criticallity"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="modalInfo.report_type == 'record'"
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Jenis Record"
            label-for="jenis_record "
          >
            <b-form-input
              id="jenis_record"
              size="sm"
              v-model="modalInfo.jenis_record"
              disabled
            ></b-form-input> </b-form-group></b-col
        ><b-col
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Rekomendasi "
            label-for="rekomendasi "
          >
            <vue-editor
              id="rekomendasi "
              :editorToolbar="customToolbar"
              v-model="modalInfo.recomendation"
              :disabled="true"
            ></vue-editor> </b-form-group
          ><b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Priority"
            label-for="priority "
          >
            <b-form-input
              id="priority"
              size="sm"
              v-model="modalInfo.priority"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Repair Taken"
            label-for="repair_taken"
          >
            <vue-editor
              id="repair_taken"
              :editorToolbar="customToolbar"
              v-model="modalInfo.repair_taken"
              :disabled="true"
            ></vue-editor>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Status"
            label-for="status"
          >
            <b-form-input id="status" size="sm" v-model="modalInfo.status" disabled></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Future Recomendation"
            label-for="future_recomendation"
          >
            <vue-editor
              id="future_recomendation"
              :editorToolbar="customToolbar"
              v-model="modalInfo.future_recomendation"
              :disabled="true"
            ></vue-editor>
          </b-form-group>
          <b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
            <b-col sm="5" class="text-sm-left">File</b-col>
            <b-col>
              <b-button
                v-if="modalInfo.file"
                variant="outline-info"
                size="sm"
                :href="urlStorage + '/file/report/' + modalInfo.file"
                target="_blank"
              >
                <span class="svg-icon svg-icon-sm svg-icon-info">
                  <i class="flaticon-eye text-success"></i>
                </span>
                Lihat File
              </b-button>
              <span v-else>Tidak ada file.</span>
            </b-col>
          </b-row>
          <b-form-group
            v-if="modalInfo.report_type == 'record'"
            label-cols-sm="5"
            label-cols-lg="5"
            content-cols-sm
            content-cols-lg="7"
            label="Deskripsi"
            label-for="deskripsi"
          >
            <vue-editor
              id="deskripsi"
              :editorToolbar="customToolbar"
              v-model="modalInfo.description"
              :disabled="true"
            ></vue-editor> </b-form-group></b-col></b-row
      ><b-row class="mb-2">
        <b-col md="6" offset-md="3">
          <b-carousel
            v-if="modalInfo.image"
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <!-- Text slides with image -->
            <b-carousel-slide
              v-for="(img, index) in JSON.parse(modalInfo.image)"
              :key="index"
              :img-src="urlStorage + '/images/report/' + img"
              :text="modalInfo.image_keterangan[index]"
            ></b-carousel-slide>
          </b-carousel>
          <span v-else> Tidak ada gambar. </span>
        </b-col>
      </b-row></b-modal
    >
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import Chart from 'chart.js/auto'
import { VueEditor } from 'vue2-editor'

import ReportsPerMonth from './reports-per-month'
import ReportsPerStatus from './reports-per-status'
import ReportsPerEquipment from './reports-per-equipment'

export default {
  name: 'Report',
  data() {
    return {
      slide: 0,
      urlStorage: '',
      customToolbar: [[]],
      cReportsPerMonth: '',
      cReportsPerStatus: '',
      cReportsPerEquipment: '',
      reportsPerMonth: ReportsPerMonth,
      reportsPerStatus: ReportsPerStatus,
      reportsPerEquipment: ReportsPerEquipment,
      lastreports: [],
      notifications: [],
      modalInfo: {},
      totalStatus: 0,
      totalEquipment: 0,
    }
  },
  components: { VueEditor },
  created() {
    this.urlStorage = ApiService.urlStorage()
  },
  mounted() {
    //Chart.register(ChartDataLabels);
    const ctx = document.getElementById('report')
    this.cReportsPerMonth = new Chart(ctx, this.reportsPerMonth)

    const ctxStatus = document.getElementById('report-status')
    this.cReportsPerStatus = new Chart(ctxStatus, this.reportsPerStatus)
    /*this.cReportsPerStatus = new Chart(ctxStatus, {
      plugins: [ChartDataLabels],
      data: this.reportsPerStatus.data,
    });*/

    const ctxEquipment = document.getElementById('report-equipment')
    this.cReportsPerEquipment = new Chart(ctxEquipment, this.reportsPerEquipment)

    this.loadChartEquipment()
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    viewReport(index) {
      if (this.lastreports[index].method == 'null') {
        this.lastreports[index].method = '-'
      }
      if (this.lastreports[index].status == 'null') {
        this.lastreports[index].status = '-'
      }
      if (this.lastreports[index].priority == 'null') {
        this.lastreports[index].priority = '-'
      }
      this.modalInfo.image_keterangan = JSON.parse(this.lastreports[index].image_keterangan)
      this.modalInfo = this.lastreports[index]
      this.$root.$emit('bv::show::modal', 'info-modal')
    },
    reportDetail(id) {
      this.$router.push({ name: 'Edit Report', params: { id: id } })
    },
    async loadChartEquipment() {
      await ApiService.get('/home/getChartData?data=report')
        .then(({ data }) => {
          this.cReportsPerMonth.data.labels = []
          this.cReportsPerMonth.data.datasets.forEach(dataset => {
            dataset.data = []
          })
          this.cReportsPerStatus.data.labels = []
          this.cReportsPerStatus.data.datasets.forEach(dataset => {
            dataset.data = []
          })
          this.cReportsPerEquipment.data.labels = []
          this.cReportsPerEquipment.data.datasets.forEach(dataset => {
            dataset.data = []
          })
          for (const item of data.report) {
            this.cReportsPerMonth.data.labels.push(item.bulan)
            this.cReportsPerMonth.data.datasets.forEach(dataset => {
              dataset.data.push(item.jumlah)
            })
          }
          this.cReportsPerMonth.update()
          //var totalStatus = 0;
          for (const item of data.status) {
            this.cReportsPerStatus.data.labels.push(item.status)
            this.cReportsPerStatus.data.datasets.forEach(dataset => {
              dataset.data.push(item.jumlah)
              this.totalStatus = this.totalStatus + item.jumlah
            })
          }
          //this.totalStatus = totalStatus;
          /*this.cReportsPerStatus.options.plugins.title.text = [
            "Reports per Status",
            "Total " + totalStatus,
          ];*/
          this.cReportsPerStatus.update()
          for (const item of data.equipment) {
            this.cReportsPerEquipment.data.labels.push(item.nama_equipment)
            this.cReportsPerEquipment.data.datasets.forEach(dataset => {
              dataset.data.push(item.total)
              this.totalEquipment = this.totalEquipment + item.total
            })
          }
          /*this.cReportsPerEquipment.options.plugins.title.text = [
            "Reports per Equipment",
            "Total " + totalEquipment,
          ];*/
          this.cReportsPerEquipment.update()

          this.lastreports = data.lastreport
          this.notifications = data.notifikasi
        })
        .catch(response => {
          console.log(response)
        })
    },
  },
}
</script>
