<template>
  <KTCodePreview v-bind:title="''">
    <template v-slot:preview><report /></template>
  </KTCodePreview>
</template>
<script>
import Report from '@/components/home/Report.vue'
import KTCodePreview from '@/view/content/CodePreview.vue'
export default {
  name: 'newhome',
  components: {
    Report,
    KTCodePreview,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>
